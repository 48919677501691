import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row dashsboard" }
const _hoisted_2 = { class: "row mb-8" }
const _hoisted_3 = { class: "row mb-8" }
const _hoisted_4 = {
  class: "col-md-5 mb-8 mb-md-0",
  id: "conversaoPacotes-card"
}
const _hoisted_5 = { class: "row mb-8" }
const _hoisted_6 = {
  class: "col-md-7 mb-8 mb-md-0",
  id: "produtosMaisVendidos-card"
}
const _hoisted_7 = {
  class: "col-md-5",
  id: "faturamentoUltimos6Meses-card"
}
const _hoisted_8 = {
  id: "checklists-card",
  class: "mb-8"
}
const _hoisted_9 = {
  id: "oficinaDigital-card",
  class: "mb-8"
}
const _hoisted_10 = { id: "pesquisaSatisfacao-card" }
const _hoisted_11 = { id: "usoFerramenta-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IndicadoresVendasCard = _resolveComponent("IndicadoresVendasCard")!
  const _component_FaturamentoTotalCard = _resolveComponent("FaturamentoTotalCard")!
  const _component_ConversaoPacotesCard = _resolveComponent("ConversaoPacotesCard")!
  const _component_ConsultoresCard = _resolveComponent("ConsultoresCard")!
  const _component_ProdutosMaisVendidosCard = _resolveComponent("ProdutosMaisVendidosCard")!
  const _component_FaturamentoUltimos6MesesCard = _resolveComponent("FaturamentoUltimos6MesesCard")!
  const _component_ChecklistsCard = _resolveComponent("ChecklistsCard")!
  const _component_OficinaDigitalCard = _resolveComponent("OficinaDigitalCard")!
  const _component_PesquisaSatisfacaoCard = _resolveComponent("PesquisaSatisfacaoCard")!
  const _component_UsoFerramentaCard = _resolveComponent("UsoFerramentaCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.temChecklist ? 'col-md-12 col-lg-9' : 'col-12')
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.temPacote || _ctx.temChecklist ? 'col-md-5' : 'col-md-6'),
          id: "indicadores-card"
        }, [
          _createVNode(_component_IndicadoresVendasCard, {
            filters: _ctx.filters,
            dataFaturamentoTotal: _ctx.indicadoresVendas,
            loading: _ctx.loadingFaturamentoTotal,
            metas: _ctx.metasConcessionaria
          }, null, 8, ["filters", "dataFaturamentoTotal", "loading", "metas"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.temPacote || _ctx.temChecklist ? 'col-md-7' : 'col-md-6'),
          id: "faturamento-total-card"
        }, [
          _createVNode(_component_FaturamentoTotalCard, {
            temPacote: _ctx.temPacote,
            filters: _ctx.filters,
            dataFaturamentoTotal: _ctx.dataFaturamentoTotal,
            loading: _ctx.loadingFaturamentoTotal
          }, null, 8, ["temPacote", "filters", "dataFaturamentoTotal", "loading"])
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ConversaoPacotesCard, {
            filters: _ctx.filters,
            percentualConversaoPacotes: _ctx.percentualConversaoPacotes,
            loadingPercentual: _ctx.loadingFaturamentoTotal
          }, null, 8, ["filters", "percentualConversaoPacotes", "loadingPercentual"])
        ], 512), [
          [_vShow, _ctx.temPacote]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.temPacote ? 'col-md-7' : 'col-12'),
          id: "consultores-card"
        }, [
          _createVNode(_component_ConsultoresCard, {
            temPacote: _ctx.temPacote,
            filters: _ctx.filters,
            metas: _ctx.metasConcessionaria
          }, null, 8, ["temPacote", "filters", "metas"])
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_ProdutosMaisVendidosCard, {
            filters: _ctx.filters,
            somaRevisoes: _ctx.somaRevisoes
          }, null, 8, ["filters", "somaRevisoes"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_FaturamentoUltimos6MesesCard, {
            temPacote: _ctx.temPacote,
            filters: _ctx.filters,
            metas: _ctx.metasConcessionaria
          }, null, 8, ["temPacote", "filters", "metas"])
        ])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.temChecklist ? 'col-md-12 col-lg-3' : 'col-12')
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ChecklistsCard, { filters: _ctx.filters }, null, 8, ["filters"])
      ], 512), [
        [_vShow, _ctx.temChecklist]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_9, [
        _createVNode(_component_OficinaDigitalCard, { filters: _ctx.filters }, null, 8, ["filters"])
      ], 512), [
        [_vShow, _ctx.temOficinaDigital]
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_PesquisaSatisfacaoCard, { filters: _ctx.filters }, null, 8, ["filters"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_UsoFerramentaCard, {
          filters: _ctx.filters,
          temChecklist: _ctx.temChecklist
        }, null, 8, ["filters", "temChecklist"])
      ])
    ], 2)
  ]))
}